import React, { ReactElement } from "react";

type Props = {
  className?: string;
};

export default function IconBack(props: Props): ReactElement<Props> {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 492 492"
      className={className}
    >
      <path d="M199 246L383 62a27 27 0 000-38L367 8c-6-5-12-8-19-8s-14 3-20 8L109 227a27 27 0 000 38l219 219a27 27 0 0038 0l16-16c11-10 11-28 0-38L199 246z" />
    </svg>
  );
}
