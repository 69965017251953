import styles from "./Bio.module.css";

import React, { ReactElement } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import IconGithub from "./IconGithub";
import IconLinkedin from "./IconLinkedin";
import IconTwitter from "./IconTwitter";
import Text from "./Text";

const Bio = (): ReactElement => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/ava.png/" }) {
        childImageSharp {
          fixed(width: 128, height: 128) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          social {
            twitter
            linkedin
            github
          }
        }
      }
    }
  `);

  const {
    social: { twitter, linkedin, github },
  } = data.site.siteMetadata;

  return (
    <div className={styles.container}>
      <div className={styles.imageAvatarWrapper}>
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          className={styles.imageAvatar}
        />
      </div>
      <div className={styles.iconContainer}>
        <a
          href={`https://twitter.com/${twitter}`}
          className={styles.iconLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconTwitter className={styles.icon} />
        </a>
        <a
          href={`https://www.linkedin.com/in/${linkedin}/`}
          className={styles.iconLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconLinkedin className={styles.icon} />
        </a>
        <a
          href={`https://github.com/${github}`}
          className={styles.iconLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconGithub className={styles.icon} />
        </a>
      </div>
      <Text tag="h1" weight={600} size={32} className={styles.heading}>
        Hi, I&#39;m Bui!
      </Text>
      <Text size={18} className={styles.caption}>
        I have been a web enthusiast since 2015. I am currently based in
        Singapore working as senior frontend developer at{" "}
        <Text tag="span" weight={600}>
          <a
            href="https://www.coinbase.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Coinbase
          </a>
        </Text>
        . My main interest is <strong>Web Performance</strong> in its entirety.
      </Text>
      <Text size={18} className={styles.caption}>
        When I&#39;m not coding, I enjoy learning Japanese 📚, playing JRPG
        games 🎮 and browsing 4chan 🍀.
      </Text>
    </div>
  );
};

export default Bio;
