import styles from "./index.module.css";

import React, { ReactElement } from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/Bio";
import IconBack from "../components/IconBack";
import Layout from "../components/Layout";
import PostItem from "../components/PostItem";
import SEO from "../components/SEO";
import Text from "../components/Text";

type Props = {
  data: {
    recentPosts: {
      edges: [
        {
          node: {
            timeToRead: number;
            fields: {
              slug: string;
            };
            frontmatter: {
              date: string;
              description: string;
              tags: string[];
              title: string;
              github?: string;
              url?: string;
            };
          };
        },
      ];
    };
    sideProjects: {
      edges: [
        {
          node: {
            timeToRead: number;
            fields: {
              slug: string;
            };
            frontmatter: {
              date: string;
              description: string;
              tags: string[];
              title: string;
              github?: string;
              url?: string;
            };
          };
        },
      ];
    };
  };
};

export default function PageHome(props: Props): ReactElement<Props> {
  const {
    data: { recentPosts, sideProjects },
  } = props;

  return (
    <Layout>
      <SEO title="Welcome" />
      <Bio />
      <Text tag="h2" size={28} weight={700} className={styles.heading}>
        Personal Hacks
      </Text>
      {sideProjects.edges.map(({ node }) => {
        return (
          <PostItem
            {...node}
            key={node.fields.slug}
            className={styles.postItem}
          />
        );
      })}
      <Text tag="h2" size={28} weight={700} className={styles.heading}>
        Recent Posts
      </Text>
      {recentPosts.edges.map(({ node }) => {
        return (
          <PostItem
            {...node}
            key={node.fields.slug}
            className={styles.postItem}
          />
        );
      })}
      <Text weight={600}>
        <Link to="/posts" className={styles.moreLink}>
          View All Posts <IconBack className={styles.moreLinkIcon} />
        </Link>
      </Text>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    recentPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            description
            tags
            title
            github
            url
          }
        }
      }
    }
    sideProjects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["Case Study"] } } }
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            description
            tags
            github
            url
          }
        }
      }
    }
  }
`;
