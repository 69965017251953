import React, { ReactElement } from "react";

type Props = {
  className?: string;
};

export default function IconLinkedin(props: Props): ReactElement<Props> {
  const { className = "" } = props;

  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M256 0a256 256 0 100 512 256 256 0 000-512zm-74.4 387h-62.3V199.4h62.3zm-31.2-213.2h-.4c-20.9 0-34.4-14.4-34.4-32.4 0-18.4 14-32.4 35.3-32.4 21.3 0 34.4 14 34.8 32.4 0 18-13.5 32.4-35.3 32.4zm256 213.2h-62.3V286.7c0-25.3-9-42.5-31.6-42.5-17.2 0-27.5 11.6-32 22.8-1.6 4-2 9.6-2 15.2V387H216s.8-170 0-187.6h62.4V226a61.9 61.9 0 0156.1-31c41 0 71.8 26.8 71.8 84.4zm0 0" />
    </svg>
  );
}
